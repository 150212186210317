<template>
  <div>
    <portal to="page-top-title">Sender #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'senders-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/senders/${id}`" data-prop="sender" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ sender.name }}</column-info>
          <column-info title="Category">
            <router-link :to="{ name: 'senders-categories-single', params: { id: sender.category.id } }">{{ sender.category.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ sender.created_at }}</column-info>
          <column-info title="Updated At">{{ sender.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="sender.active"/>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import sender from '@/modules/strubs/sender'

export default {
  name: 'SendersSingle',
  metaInfo () {
    return { title: 'Sender #' + this.id }
  },
  components: {
    BoxSingle,
    BadgeActive,
    Btn
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      sender: sender
    }
  }
}
</script>
