import senderCategory from '@/modules/strubs/senderCategory'

export default {
  id: 0,
  name: '',
  active: false,
  updated_at: '',
  created_at: '',
  category: senderCategory,
  metadata: {
    editable: {
      name: false
    }
  }
}
